<template>
  <div class="login">
    <atom-spinner v-if="isLoading" slot="loading" :animation-duration="1500" :size="100" color="#1a76d2" />
    <template v-else>
      <h3>Email has been verified successfully</h3>
      <router-link :to="{ name: 'login' }" class="btn btn-primary d-block">Login</router-link>
    </template>
  </div>
</template>

<script>

export default {
  components: {
  },

  data () {
    return {
      token: this.$route.query.token,
      isLoading: true,
    }
  },

  mounted () {
    this.verifyEmail()
  },

  methods: {
    verifyEmail () {
      this.error = {}

      this.$store.dispatch('auth/verifyEmail', { token: this.token })
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$router.push({ name: 'login' })
        })
    },
  },
}
</script>

<style scoped>
  .atom-spinner {
    margin: 0 auto;
  }

  h3 {
    color: #0b9a07;
    text-align: center;
  }
</style>